import React from 'react';

const GoogleMap = () => {
  return (
    <div className="row">
      {/* Contenedor responsivo */}
      <div className="embed-responsive embed-responsive-16by9">
        {/* Mapa de Google */}
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d234.9187301116412!2d-99.27023538350917!3d19.597362859708642!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d21c0e7fb9dd53%3A0xb0cc96e1713f7a8d!2sGladiola%204%2C%20Ex%20Hacienda%20del%20Pedregal%20Lopez%20Mateos%202da%20Secc%2C%2052918%20Cdad.%20L%C3%B3pez%20Mateos%2C%20M%C3%A9x.!5e0!3m2!1ses-419!2smx!4v1717000684299!5m2!1ses-419!2smx" 
          width="600" 
          height="400" 
          className="embed-responsive-item"
          style={{ border: '0' }} 
          allowFullScreen="" 
          loading="lazy" 
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default GoogleMap;
